import React, { useCallback, useEffect, useState } from 'react';
import Close from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import Button from '@truescope-web/react/lib/components/form/Button';
import Select from '@truescope-web/react/lib/components/form/Select';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Inline, { horizontalAlignment } from '@truescope-web/react/lib/components/layout/Inline';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import SkeletonWrapper from '@truescope-web/react/lib/components/loading/SkeletonWrapper';
import { useSheet } from '@truescope-web/react/lib/components/modal/Sheet';
import { arrayIsNullOrEmpty, createLookup } from '@truescope-web/utils/lib/arrays';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { extractError } from '../../../../../components/Api';
import { useApiLookup } from '../../../../../components/ApiLookupProvider';
import { getScopes } from '../../../../Content/Scopes/ScopeConstants';
import { addScopesToWorkspace } from './api';

const addOptionToCollection = (currentOptions, newOption) => {
	return [...currentOptions, newOption].sort((a, b) => a.label.localeCompare(b.label));
};

const removeOptionFromCollection = (currentOptions, index) => {
	const updatedOptions = [...currentOptions];
	updatedOptions.splice(index, 1);
	return updatedOptions;
};

const AddScopesSheet = ({ workspace_id, existingScopes, onAddScopes }) => {
	const [getDatahubApi] = useApiLookup();
	const { closeSheet } = useSheet();
	const [isLoading, setIsLoading] = useState(false);
	const [isPartialLoading, setIsPartialLoading] = useState(false);
	const [scopeOptions, setScopeOptions] = useState([]);
	const [errorMessage, setErrorMessage] = useState([]);
	const [selectedScopeOptions, setSelectedScopeOptions] = useState([]);

	const initialize = useCallback(async () => {
		try {
			setIsLoading(true);
			setErrorMessage(null);
			const { scopeOptions } = await getScopes(getDatahubApi, { format: 'options' });
			const excludedLookup = createLookup(existingScopes, 'scope_id');
			const trimmedOptions = scopeOptions.filter(({ value }) => isNullOrUndefined(excludedLookup[value]));
			setScopeOptions(trimmedOptions);
		} catch (e) {
			setErrorMessage(extractError(e));
		} finally {
			setIsLoading(false);
		}
	}, [setIsLoading, setScopeOptions, setErrorMessage, getDatahubApi, existingScopes]);

	useEffect(() => {
		initialize();
	}, [initialize]);

	const handleAdd = async () => {
		try {
			setIsPartialLoading(true);
			setErrorMessage(null);

			const workspaceScopes = await addScopesToWorkspace(
				getDatahubApi,
				workspace_id,
				selectedScopeOptions.map(({ value }) => value)
			);

			const nameLookup = selectedScopeOptions.reduce((lookup, { label, value }) => {
				lookup[value] = label;
				return lookup;
			}, {});

			if (!isNullOrUndefined(onAddScopes)) {
				onAddScopes(
					workspaceScopes.map((workspaceScope) => ({
						...workspaceScope,
						name: nameLookup[workspaceScope.scope_id]
					}))
				);
			}

			closeSheet();
		} catch (e) {
			setErrorMessage(extractError(e));
		} finally {
			setIsPartialLoading(false);
		}
	};

	const handleRemoveScope = (scopeOption) => {
		setScopeOptions((prev) => addOptionToCollection(prev, scopeOption));
		setSelectedScopeOptions((prev) => prev.filter(({ value }) => scopeOption.value !== value));
	};

	const handleSelectScope = (_e, _value, rawValue) => {
		setSelectedScopeOptions((prev) => [...prev, rawValue]);
		setScopeOptions((prev) => {
			const index = prev.findIndex((option) => option.value === rawValue.value);
			return removeOptionFromCollection(prev, index);
		});
	};

	const renderSelectedScopeOptions = () => {
		if (arrayIsNullOrEmpty(selectedScopeOptions)) {
			return null;
		}

		return (
			<Grid item>
				<Grid container>
					{selectedScopeOptions.map((scope) => (
						<Grid item key={scope.value}>
							<Inline horizontalAlignment={horizontalAlignment.rightAlignSiblings}>
								<Typography variant="h5">{scope.label}</Typography>
								<IconButton onClick={() => handleRemoveScope(scope)} disabled={isPartialLoading}>
									<Close />
								</IconButton>
							</Inline>
						</Grid>
					))}
				</Grid>
			</Grid>
		);
	};

	if (isLoading) {
		return (
			<div className="full-width">
				<SkeletonWrapper />
			</div>
		);
	}

	if (stringIsNullOrEmpty(workspace_id)) {
		return (
			<Grid container>
				<Grid item>Workspace id has not been specified</Grid>
			</Grid>
		);
	}

	return (
		<Grid container>
			<Grid item>
				<Inline horizontalAlignment={horizontalAlignment.rightAlignSiblings}>
					<Typography variant="h4">Add Feed</Typography>
				</Inline>
			</Grid>
			<Grid item>
				<Inline horizontalAlignment={horizontalAlignment.rightAlignSiblings}>
					<p>Select one or more Feeds from the list below to add items to this Workspace.</p>
				</Inline>
			</Grid>
			<Grid item>
				<Select
					label="Feeds By Type"
					placeholder="Select a feed"
					options={scopeOptions}
					onChange={handleSelectScope}
					disabled={scopeOptions.length === 0}
					helperText={scopeOptions.length === 0 ? 'There are no more feeds that can be selected' : ''}
					value={[]}
				/>
			</Grid>
			{renderSelectedScopeOptions()}
			{stringIsNullOrEmpty(errorMessage) && (
				<Grid item>
					<Typography variant="error">{errorMessage}</Typography>
				</Grid>
			)}
			<Grid item>
				<Inline horizontalAlignment={horizontalAlignment.right}>
					<Button onClick={() => closeSheet()} variant="secondary" disabled={isPartialLoading}>
						Cancel
					</Button>
					<Button onClick={handleAdd} variant="primary" loading={isPartialLoading}>
						Add
					</Button>
				</Inline>
			</Grid>
		</Grid>
	);
};

export default AddScopesSheet;
