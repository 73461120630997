import React, { memo, useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Add from '@mui/icons-material/Add';
import MoreVert from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@truescope-web/react/lib/components/form/Button';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import { useSheet } from '@truescope-web/react/lib/components/modal/Sheet';
import { snackbarVariants, useSnackbar } from '@truescope-web/react/lib/components/modal/Snackbar';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { useApiLookup } from '../../../../../components/ApiLookupProvider';
import { WorkspaceScopesTable } from '../../../../../components/Table/WorkspaceScopesTable';
import AddScopesSheet from './AddScopesSheet';
import { removeScopeFromWorkspace, updateWorkspaceScopeSortOrder } from './api';
import { reorderScopes } from './constants';

const Scopes = ({ scopes, setScopes, workspace }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [selectedScopes, setSelectedScopes] = useState([]);
	const [getDatahubApi] = useApiLookup();
	const { showSnackbar } = useSnackbar();
	const { showSheet } = useSheet();
	const history = useHistory();

	const handleRemoveScopeClick = (_e) => {
		handleCloseMenu();
		const scopeId = selectedScopes.workspace_scope_id;
		const workspaceId = workspace.workspace_id;
		removeScopeFromWorkspace(getDatahubApi, workspaceId, scopeId).then(({ message }) => {
			if (!stringIsNullOrEmpty(message)) {
				showSnackbar(message, snackbarVariants.error);
				return;
			}

			setScopes((prev) => prev.filter((scope) => scope.workspace_scope_id !== scopeId));
		});
	};

	const handleAddScopes = useCallback(
		(newScopes) => {
			setScopes((prev) => prev.concat(newScopes));
		},
		[scopes]
	);

	const handleAddScopeClick = useCallback(
		(_e) => {
			showSheet(
				{
					label: 'Add Feeds',
					children: <AddScopesSheet workspace_id={workspace.workspace_id} existingScopes={scopes} onAddScopes={handleAddScopes} />
				},
				false
			);
		},
		[scopes]
	);

	const handleEditScopeClick = () => {
		view(selectedScopes);
	};

	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	const handleOpenMenu = (e, scope) => {
		e.preventDefault();
		e.stopPropagation();
		setAnchorEl(e.currentTarget);
		setSelectedScopes(scope);
	};

	const view = ({ scope_id }) => {
		history.push(`/media/feeds/${scope_id}`);
	};

	const renderMenu = () => {
		return (
			<Menu id="row-menu" anchorEl={anchorEl} keepMounted open={!isNullOrUndefined(anchorEl)} onClose={handleCloseMenu}>
				<MenuItem onClick={handleEditScopeClick}>
					<Typography>Edit</Typography>
				</MenuItem>
				<MenuItem onClick={handleRemoveScopeClick}>
					<Typography>Remove</Typography>
				</MenuItem>
			</Menu>
		);
	};

	const renderScopeNameColumn = ({ scope_id, name }) => {
		return (
			<div className="wrap">
				<Typography variant="row-link">
					<Link onClick={() => history.push(`/media/feeds/${scope_id}`)}>{name}</Link>
				</Typography>
			</div>
		);
	};

	const renderItemColumn = (scope_id, value) => {
		return (
			<div className="wrap">
				<Typography variant="row-link">
					<Link onClick={() => history.push(`/clients/workspaces/${scope_id}`)}>{value}</Link>
				</Typography>
			</div>
		);
	};

	const renderOptionsColumn = (scope, disabled) => {
		return (
			<div className="wrap">
				<IconButton aria-controls="row-menu" aria-haspopup="true" disabled={disabled} onClick={(e) => handleOpenMenu(e, scope)}>
					<MoreVert />
				</IconButton>
			</div>
		);
	};

	const onTableItemChange = useCallback(
		(scope, draggedFromIndex, draggedToIndex) => {
			let previousScopes = [];
			setScopes((prev) => {
				previousScopes = prev;

				return reorderScopes(scopes, draggedFromIndex, draggedToIndex);
			});

			updateWorkspaceScopeSortOrder(
				getDatahubApi,
				workspace.workspace_id,
				scope.workspace_scope_id,
				draggedToIndex + 1,
				draggedFromIndex + 1
			).then(({ message }) => {
				if (!stringIsNullOrEmpty(message)) {
					showSnackbar(message, snackbarVariants.error);
					setScopes(previousScopes);
				}
			});
		},
		[workspace.workspace_id, getDatahubApi, scopes, setScopes]
	);

	const tableData = useMemo(
		() =>
			scopes.map((scope) => ({
				...scope,
				id: scope.scope_id,
				nameLink: renderScopeNameColumn(scope),
				itemCountMonthLink: renderItemColumn(scope.scope_id, scope.item_count_month),
				itemCountForecastLink: renderItemColumn(scope.scope_id, scope.item_count_forecast),
				menu: renderOptionsColumn(scope, false)
			})),
		[scopes]
	);
	const tableColumns = useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'nameLink'
			},
			{
				Header: () => (
					<div className="workspace-scopes__table__options__header">
						<Button fab={<Add />} tooltip="Add Feed" variant="primary" onClick={handleAddScopeClick} />
					</div>
				),
				Cell: (row) => {
					return <div className="workspace-scopes__table__options__header__row">{row.value}</div>;
				},
				accessor: 'menu',
				disableSortBy: true
			}
		],
		[handleAddScopeClick]
	);

	return (
		<div className="workspace-scopes">
			<Grid item className="workspace-scopes__title">
				<Typography variant="subtitle">Add Feeds to this Workspace</Typography>
			</Grid>
			<WorkspaceScopesTable columns={tableColumns} data={tableData} onChange={onTableItemChange} />
			{renderMenu()}
		</div>
	);
};

export default memo(Scopes);
