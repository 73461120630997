import React, { useEffect, useMemo, useState } from 'react';
import Select from '@truescope-web/react/lib/components/form/Select';
import Switch from '@truescope-web/react/lib/components/form/Switch';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Inline from '@truescope-web/react/lib/components/layout/Inline';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { useApiLookup } from '../../../../../components/ApiLookupProvider';
import { getCurrencies } from './constants';

const Options = ({
	market,
	workspace,
	onEnableNlpChange,
	onCurrencyCodeChange,
	onShowAveChange,
	disabled,
	onEnableMediaAssistantChange,
	onEnableAiSummarizationChange
}) => {
	const [getDatahubApi] = useApiLookup();
	const [currencyOptions, setCurrencyOptions] = useState([]);
	useEffect(() => {
		getCurrencies(getDatahubApi).then((newCurrencyOptions) => setCurrencyOptions(newCurrencyOptions));
	}, [getDatahubApi]);

	const currentCurrency = useMemo(
		() => (isNullOrUndefined(workspace.currency_code) ? undefined : { label: workspace.currency_code, value: workspace.currency_code }),
		[workspace?.currency_code]
	);

	return (
		<Grid container>
			<Grid item>
				<Typography variant="h4">Workspace Options</Typography>
			</Grid>
			<Grid item sm={6}>
				<Switch
					value={workspace.enable_nlp}
					onChange={onEnableNlpChange}
					label="Run NLP on matched items"
					color="primary"
					disabled={disabled}
				/>
			</Grid>
			<Grid item sm={6}>
				<Inline>
					<Switch value={workspace.show_ave} onChange={onShowAveChange} label="Show AVE" color="primary" disabled={disabled} />
					{workspace.show_ave && (
						<Select
							options={currencyOptions}
							value={currentCurrency}
							placeholder={`Use Market Currency (${market.currency_code})`}
							onChange={onCurrencyCodeChange}
							menuPosition="fixed"
							clearable
							disabled={disabled}
						/>
					)}
				</Inline>
			</Grid>
			<Grid item sm={6}>
				<Switch
					value={workspace.enable_media_assistant}
					onChange={onEnableMediaAssistantChange}
					label="Enable Media Assistant"
					color="primary"
					disabled={disabled}
				/>
			</Grid>
			<Grid item sm={6}>
				<Switch
					value={workspace.enable_ai_summarization}
					onChange={onEnableAiSummarizationChange}
					label="Enable AI Summarization"
					color="primary"
					disabled={workspace.enable_media_assistant || disabled}
				/>
			</Grid>
		</Grid>
	);
};

export default Options;
