import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Accordion from '@truescope-web/react/lib/components/layout/Accordion';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import { useSheet } from '@truescope-web/react/lib/components/modal/Sheet';
import { snackbarVariants, useSnackbar } from '@truescope-web/react/lib/components/modal/Snackbar';
import DebugView from '@truescope-web/react/lib/components/widgets/DebugView';
import { sourceReferenceTypes } from '@truescope-web/utils/lib/entityHelpers';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { useApiLookup } from '../../../components/ApiLookupProvider';
import '../../../scss/views/Entities/EntitiesList/Entity.scss';
import Aliases from './Aliases';
import SocialHandles from './SocialHandles';
import { getEntity } from './constants';

function Entity({ id, index: parentIndex, setAliasCount, socialNetworks, handleSearch, setIsLoading: parentSetIsLoading }) {
	const [getDatahubApi] = useApiLookup();
	const [entity, setEntity] = useState();
	const [aliases, setAliases] = useState();
	const [socialHandles, setSocialHandles] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [shouldReloadSearch, setShouldReloadSearch] = useState(false);
	const { showSnackbar } = useSnackbar();
	const { closeSheet } = useSheet();

	useEffect(() => {
		setIsLoading(true);
		getEntity(getDatahubApi, id)
			.then(({ entity, aliases, socialHandles }) => {
				setEntity(entity);
				setAliases(aliases);
				setSocialHandles(socialHandles);
				setIsLoading(false);
			})
			.catch(() => {
				showSnackbar(`We're unable to find that entity, please try again.`, snackbarVariants.error);
				setIsLoading(false);
				setShouldReloadSearch(true);
				closeSheet();
			});
	}, [id]);

	useEffect(() => {
		return () => {
			if (shouldReloadSearch) {
				parentSetIsLoading(true);
				handleSearch(0).then(() => parentSetIsLoading(false));
			}
		};
	}, [shouldReloadSearch]);

	const removeAlias = (index) => {
		const newAliases = [...aliases];
		newAliases.splice(index, 1);
		setAliases(newAliases);
		setAliasCount(parentIndex);
		setShouldReloadSearch(true);
	};

	const getAccordionItems = () => [
		{
			label: 'Aliases',
			content: <Aliases parent={entity} aliases={aliases} removeAlias={removeAlias} />
		},
		{
			label: 'Social Handles',
			content: (
				<SocialHandles
					socialNetworks={socialNetworks}
					socialHandles={socialHandles}
					sourceReference={entity.extracted_entity_id}
					sourceReferenceType={sourceReferenceTypes.extractedEntities}
				/>
			)
		},
		{
			label: 'JSON',
			content: (
				<div>
					<Typography variant="h2" bold>
						Parent
					</Typography>
					{<DebugView object={entity} />}

					<Typography variant="h2" bold>
						Aliases
					</Typography>
					{<DebugView object={aliases} />}
					{<DebugView object={entity} />}

					<Typography variant="h2" bold>
						Social Handles
					</Typography>
					{<DebugView object={socialHandles} />}
				</div>
			)
		}
	];

	if (isLoading || isNullOrUndefined(entity)) {
		return (
			<div id="entity" className="loading">
				<CircularProgress size={50} />
			</div>
		);
	}

	return (
		<div id="entity">
			<Typography variant="h2" bold>
				{entity.name}
			</Typography>
			<Accordion items={getAccordionItems()} initialExpandedIndex={0} />
		</div>
	);
}

export default Entity;
