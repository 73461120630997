import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import { Autocomplete } from '@mui/material';
import Paper from '@mui/material/Paper';
import { useUserStorageContext } from '@truescope-web/react/lib/components/UserStorageProvider';
import Button from '@truescope-web/react/lib/components/form/Button';
import TextField from '@truescope-web/react/lib/components/form/TextField';
import Content from '@truescope-web/react/lib/components/layout/Content';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Inline, { horizontalAlignment } from '@truescope-web/react/lib/components/layout/Inline';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import { useSheet } from '@truescope-web/react/lib/components/modal/Sheet';
import DatePicker from '@truescope-web/react/lib/components/widgets/DatePicker';
import { dateOptionsLookup, requiresStartOfWeek } from '@truescope-web/utils/lib/dates';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import Header from '../../../components/Header';
import MediaItemTable from '../../../components/Table/MediaItemTable';
import MediaItemBuilder from '../MediaItem/MediaItemBuilder';

const maxSearches = 20;

const Search = () => {
	const location = useLocation();
	const urlQuery = new URLSearchParams(location.search).get('query');
	const history = useHistory();
	const { userStorage, setUserStorage } = useUserStorageContext();
	const [search, setSearch] = useState(null);
	const [isMediaItemTableLoading, setIsMediaItemTableLoading] = useState(false);
	const [dataTriggerGuid, setDataTriggerGuid] = useState(null);
	const [queryText, setQueryText] = useState(null);
	const { showSheet, closeSheet } = useSheet();

	const [filter, setFilter] = useState({
		publication_date_option: dateOptionsLookup.last30Days,
		publication_date_from: null,
		publication_date_to: null,
		time_zone: moment().format('Z')
	});

	useEffect(() => {
		if (!isNullOrUndefined(location.state)) {
			setSearch(location.state.queryText);
			saveSearch(location.state.queryText);
			setQueryText(location.state.queryText);
			setDataTriggerGuid(uuidv4());
			return;
		}
		if (stringIsNullOrEmpty(urlQuery)) {
			return;
		}
		setQueryText(urlQuery);
		setDataTriggerGuid(uuidv4());
	}, [setQueryText, setDataTriggerGuid]);

	useEffect(() => {
		if (!isNullOrUndefined(urlQuery)) {
			setSearch(urlQuery);
			setQueryText(urlQuery);
			handleSearch();
		}
	}, [urlQuery]);

	useEffect(() => {
		if (urlQuery !== queryText) {
			history.replace({ search: null });
		}
	}, [queryText]);

	const saveSearch = (searchString) => {
		if (stringIsNullOrEmpty(searchString)) {
			return;
		}
		setUserStorage((prev) => {
			const savedSearches = prev.savedSearches || [];
			if (savedSearches.length >= maxSearches) {
				savedSearches.pop();
			}
			if (savedSearches.includes(searchString)) {
				return {
					...prev
				};
			}
			return {
				...prev,
				savedSearches: [searchString].concat(savedSearches)
			};
		});
	};

	const handleSearch = (isSearchNew = true) => {
		setDataTriggerGuid(uuidv4());
		if (isSearchNew) {
			saveSearch(queryText);
		}
	};

	const handleAddContent = () => {
		showSheet({
			label: 'Add Content',
			children: (
				<MediaItemBuilder
					closeSheet={() => {
						setDataTriggerGuid(uuidv4());
						closeSheet();
					}}
				/>
			)
		});
	};

	const handleDatePickerChange = (dateOption, dateFrom, dateTo) => {
		const newFilter = {
			publication_date_option: dateOption,
			publication_date_from: dateFrom,
			publication_date_to: dateTo,
			time_zone: moment().format('Z')
		};

		if (requiresStartOfWeek(dateOption)) {
			newFilter.start_of_week = moment().startOf('week').format('dddd').toLowerCase();
		}

		setFilter(newFilter);
		handleSearch();
	};

	const handleAutoCompleteSelection = (newSearch) => {
		setQueryText(newSearch);
		handleSearch(false);
	};

	const handleSearchInputChange = (event, newValue) => {
		setSearch(newValue);
		handleAutoCompleteSelection(newValue);
	};

	const renderSearch = () => {
		return (
			<Fragment>
				<Grid item>
					<Inline horizontalAlignment={horizontalAlignment.rightAlignSiblings}>
						<Autocomplete
							className="search-autocomplete"
							disableClearable
							classes={{
								listbox: 'search-autocomplete__listbox',
								paper: 'search-autocomplete__paper'
							}}
							freeSolo
							options={userStorage.savedSearches || []}
							value={search}
							onChange={handleSearchInputChange}
							renderInput={(params) => (
								<TextField
									{...params}
									label=""
									placeholder="Search media items..."
									InputProps={{
										...params.InputProps,
										type: 'search'
									}}
									value={queryText}
									onChange={(e) => setQueryText(e.target.value)}
									onEnter={handleSearch}
									disabled={isMediaItemTableLoading}
								/>
							)}
						/>
						<DatePicker
							dateOption={filter.publication_date_option}
							dateFrom={filter.publication_date_from}
							dateTo={filter.publication_date_to}
							onAccept={handleDatePickerChange}
						/>
						<Button id="search-button" disabled={isMediaItemTableLoading} onClick={handleSearch} aria-label="Media search">
							Search
						</Button>
					</Inline>
				</Grid>
			</Fragment>
		);
	};

	const renderResults = () => {
		return (
			<Grid item>
				<Paper elevation={0}>
					<MediaItemTable
						queryText={queryText || ''}
						dataTriggerGuid={dataTriggerGuid}
						includeLanguageFields
						useWhereClauses
						requestParams={filter}
						setIsLoading={setIsMediaItemTableLoading}
					/>
				</Paper>
			</Grid>
		);
	};

	return (
		<Content>
			<Header />
			<Inline horizontalAlignment={horizontalAlignment.rightAlignSiblings}>
				<Header
					header={
						<Typography variant="h1" bold>
							Search
						</Typography>
					}
					breadcrumbs={false}
				/>
				<Button fab={<Add />} tooltip="Add Content" variant="primary" onClick={handleAddContent} />
			</Inline>
			<Grid container>
				{renderSearch()}
				{renderResults()}
			</Grid>
		</Content>
	);
};

export default Search;
