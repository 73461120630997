import React, { useCallback, useEffect, useState } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import { useSheet } from '@truescope-web/react/lib/components/modal/Sheet';
import { snackbarVariants, useSnackbar } from '@truescope-web/react/lib/components/modal/Snackbar';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { extractedEntityTypesOptions } from '@truescope-web/utils/lib/entityHelpers';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { abbreviateNumber, stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { useApiLookup } from '../../../components/ApiLookupProvider';
import '../../../scss/views/Entities/EntitiesList/Table.scss';
import Entity from './Entity';
import ParentDialog from './ParentDialog';
import TableHead from './TableHead';
import ToolBar from './TableToolbar';
import { hideOrShowEntities } from './constants';
import { useEntities } from './provider';

const Loader = ({ isLoading = false }) =>
	isLoading && (
		<div className="loader">
			<CircularProgress />
		</div>
	);

function EntitiesTable() {
	const {
		setIsLoading,
		isLoading,
		entities: items,
		setEntities,
		selectedEntities,
		setSelectedEntities,
		setOpenParentDialog,
		setIsLoadingHideShow,
		socialNetworks,
		search
	} = useEntities();
	const [getDatahubApi] = useApiLookup();

	const { showSnackbar } = useSnackbar();
	const { showSheet, closeSheet } = useSheet();
	const [shouldShowHideLink, setShouldShowHideLink] = useState(true);
	const itemTypes = extractedEntityTypesOptions();
	const noResults = arrayIsNullOrEmpty(items);

	useEffect(() => {
		if (arrayIsNullOrEmpty(selectedEntities)) {
			return;
		}

		if (arrayIsNullOrEmpty(items)) {
			setSelectedEntities([]);
			return;
		}

		const existingSelectedItems = (items || []).reduce((acc, { id }) => (selectedEntities.includes(id) ? acc.concat(id) : acc), []);
		setSelectedEntities(existingSelectedItems);
		determineShowHide(existingSelectedItems);
	}, [items]);

	const handleSelectAllClick = useCallback(
		(event) => {
			if (event.target.checked) {
				const selectedItems = items.map((n) => n.id);
				setSelectedEntities(selectedItems);

				determineShowHide(selectedItems);
				return;
			}
			setSelectedEntities([]);
		},
		[items]
	);

	const isSelected = (id) => selectedEntities.indexOf(id) !== -1;

	const handleSelectClick = useCallback(
		(event) => {
			const dataSet = event.currentTarget.dataset;
			const id = parseInt(dataSet.id, 0);

			const selectedIndex = selectedEntities.indexOf(id);
			let newSelected = [];

			if (selectedIndex === -1) {
				newSelected = newSelected.concat(selectedEntities, id);
			} else if (selectedIndex === 0) {
				newSelected = newSelected.concat(selectedEntities.slice(1));
			} else if (selectedIndex === selectedEntities.length - 1) {
				newSelected = newSelected.concat(selectedEntities.slice(0, -1));
			} else if (selectedIndex > 0) {
				newSelected = newSelected.concat(selectedEntities.slice(0, selectedIndex), selectedEntities.slice(selectedIndex + 1));
			}

			setSelectedEntities(newSelected);
			determineShowHide(newSelected);
		},
		[items, selectedEntities]
	);

	const determineShowHide = (ids) => {
		if (arrayIsNullOrEmpty(ids)) {
			return;
		}

		if (ids.length === 1) {
			setShouldShowHideLink(!items.filter(({ id }) => id === ids[0])[0].hidden);
		}

		const hiddenItemCount = items.filter(({ id }) => ids.includes(id)).reduce((acc, { hidden }) => (hidden ? ++acc : --acc), 0);
		setShouldShowHideLink(hiddenItemCount <= 0);
	};

	const handleAddParentClick = useCallback(
		(event) => {
			event.preventDefault();
			setOpenParentDialog(true);
		},
		[selectedEntities]
	);

	const setEntitiesHiddenState = (hidden = true) => {
		setEntities(
			items.map((item) => {
				return selectedEntities.indexOf(item.id) > -1 ? { ...item, hidden } : item;
			})
		);
	};

	const handleHideEntitiesClick = useCallback(
		(event) => {
			event.preventDefault();
			setIsLoadingHideShow(true);
			hideOrShowEntities(getDatahubApi, selectedEntities, true)
				.then(() => setEntitiesHiddenState(true))
				.catch(() => {
					showSnackbar(`We're unable to hide those entities, please try again.`, snackbarVariants.error);
				})
				.finally(() => setIsLoadingHideShow(false));
		},
		[selectedEntities]
	);

	const handleShowEntitiesClick = useCallback(
		async (event) => {
			event.preventDefault();
			setIsLoadingHideShow(true);
			hideOrShowEntities(getDatahubApi, selectedEntities, false)
				.then(() => setEntitiesHiddenState(false))
				.catch(() => {
					showSnackbar(`We're unable to show those entities, please try again.`, snackbarVariants.error);
				})
				.finally(() => setIsLoadingHideShow(false));
		},
		[selectedEntities]
	);

	const setAliasCount = useCallback(
		(index) => {
			const newEntities = [...items];
			newEntities[index].aliasesCount--;
			setEntities(newEntities);
		},
		[items, setEntities]
	);

	const handleOpenEntitySheet = useCallback(
		(event) => {
			const dataSet = event.currentTarget.dataset;
			const id = parseInt(dataSet.id, 0);
			const index = parseInt(dataSet.index, 0);

			if (isNullOrUndefined(id)) {
				return;
			}

			showSheet({
				label: 'Media Item',
				children: (
					<Entity
						id={id}
						index={index}
						setAliasCount={setAliasCount}
						socialNetworks={socialNetworks}
						closeSheet={closeSheet}
						handleSearch={search}
						setIsLoading={setIsLoading}
					/>
				)
			});
		},
		[setAliasCount, socialNetworks]
	);

	if (items === null) {
		return (
			<div id="entities-table">
				<div className="container">
					<Loader isLoading={isLoading} />
				</div>
			</div>
		);
	}

	return (
		<div id="entities-table">
			<div className="container">
				<Loader isLoading={isLoading} />
				{noResults ? (
					<Typography className="no-results" variant="subtitle">
						No results found
					</Typography>
				) : (
					<>
						<ToolBar
							onSelectAllClick={handleSelectAllClick}
							handleAddParentClick={handleAddParentClick}
							handleHideEntitiesClick={handleHideEntitiesClick}
							handleShowEntitiesClick={handleShowEntitiesClick}
							showHide={shouldShowHideLink}
						/>
						<TableContainer>
							<Table size="small">
								<TableHead />
								<TableBody>
									{items.map((item, index) => {
										const isItemSelected = isSelected(item.id);
										const isHidden = item.hidden;
										const isHiddenClass = isHidden ? 'is-hidden' : '';
										const itemType = itemTypes.find((type) => type.value === item.type);

										return (
											<TableRow key={item.id} hover>
												<TableCell align="left" className="checkbox">
													<Checkbox
														className="checkbox__control"
														color="primary"
														inputProps={{ 'aria-label': `Select ${item.name}` }}
														checked={isItemSelected}
														onClick={handleSelectClick}
														role="checkbox"
														aria-checked={isItemSelected}
														tabIndex={-1}
														selected={isItemSelected}
														data-id={item.id}
													/>
												</TableCell>
												<TableCell align="left" className="wiki">
													{!stringIsNullOrEmpty(item.wikipediaUrl) && (
														<a
															className="wiki-link"
															href={item.wikipediaUrl}
															target="_blank"
															rel="nofollow noopener noreferrer"
														>
															<OpenInNewIcon fontSize="inherit" />
														</a>
													)}
												</TableCell>
												<TableCell
													className={`name ${isHiddenClass}`}
													align="left"
													onClick={handleOpenEntitySheet}
													data-id={item.id}
													data-index={index}
												>
													{item.name}
												</TableCell>
												<TableCell className={isHiddenClass} align="left">
													{isNullOrUndefined(itemType) ? '' : itemType.label}
												</TableCell>
												<TableCell className={isHiddenClass} align="right">
													{item.aliasesCount}
												</TableCell>
												<TableCell className={isHiddenClass} align="right">
													{abbreviateNumber(item.averageItemsDay)}
												</TableCell>
												<TableCell className={isHiddenClass} align="left"></TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
					</>
				)}
			</div>
			<ParentDialog />
		</div>
	);
}

export default EntitiesTable;
