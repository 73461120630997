import cloneDeep from 'lodash.clonedeep';
import React, { useEffect, useMemo, useState } from 'react';
import { LinearProgress } from '@mui/material';
import Content from '@truescope-web/react/lib/components/layout/Content';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Inline, { horizontalAlignment } from '@truescope-web/react/lib/components/layout/Inline';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import SkeletonWrapper from '@truescope-web/react/lib/components/loading/SkeletonWrapper';
import Alert from '@truescope-web/react/lib/components/modal/Alert';
import { snackbarVariants, useSnackbar } from '@truescope-web/react/lib/components/modal/Snackbar';
import { htmlDateToIsoString, isoStringToHtmlFormat } from '@truescope-web/react/lib/components/widgets/DatePicker';
import { validateObject } from '@truescope-web/react/lib/utils/validation';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { useApiLookup } from '../../../../components/ApiLookupProvider';
import { useConfig } from '../../../../components/ConfigProvider';
import Header from '../../../../components/Header';
import ContentRights from './ContentRights/ContentRights';
import Details from './Details/Details';
import LayoutSeparator from './LayoutSeparator';
import Market from './Market/WorkspaceMarket';
import AiMediaAssistantModal from './Options/AiMediaAssistantModal';
import AiSummarizationModal from './Options/AiSummarizationModal';
import Options from './Options/Options';
import Plan from './Plan/Plan';
import Scopes from './Scopes/Scopes';
import Users from './Users/Users';
import { getWorkspace } from './api';
import { updateWorkspace } from './api';
import { workspaceValidationRules } from './constants';

const Workspace = ({ match, workspace_id: wid }) => {
	const [getDatahubApi] = useApiLookup();
	const { showSnackbar } = useSnackbar();
	const workspaceId = useMemo(() => (!stringIsNullOrEmpty(wid) ? wid : match.params.workspace_id), [wid, match.params.workspace_id]);
	const [showNlpPrompt, setShowNlpPrompt] = useState(false);
	const [workspaceContentRights, setWorkspaceContentRights] = useState([]);
	const [workspace, setWorkspace] = useState(null);
	const [market, setMarket] = useState(null);
	const [workspaceClient, setWorkspaceClient] = useState(null);
	const [workspaceScopes, setWorkspaceScopes] = useState([]);
	const [workspacePlan, setWorkspacePlan] = useState(null);
	const [workspaceUsers, setWorkspaceUsers] = useState([]);
	const [workspaceInvites, setWorkspaceInvites] = useState([]);
	const [isAiSummarizationModalOpen, setIsAiSummarizationModalOpen] = useState(false);
	const [isAiMediaAssistantModalOpen, setIsAiMediaAssistantModalOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [isPartialLoading, setIsPartialLoading] = useState(false);
	const [message, setMessage] = useState(null);
	const [validationState, setValidationState] = useState(null);
	const { config } = useConfig();
	const workspaceClientMarket = config.marketsLookup[workspaceClient?.market_id];

	useEffect(() => {
		setIsLoading(true);
		getWorkspace(getDatahubApi, workspaceId)
			.then(({ users, invites, workspace, scopes, plan, contentRights, market, client }) => {
				setWorkspacePlan(plan);
				setMarket(market);
				setWorkspaceClient(client);
				setWorkspaceScopes(scopes);
				setWorkspace(workspace);
				setWorkspaceUsers(users);
				setWorkspaceInvites(invites);
				setWorkspaceContentRights(contentRights);
			})
			.catch((error) => setMessage(error.message))
			.finally(() => setIsLoading(false));
	}, [getDatahubApi, workspaceId]);

	const saveWorkspace = async (updatedWorkspace) => {
		setIsPartialLoading(true);
		const previousWorkspace = cloneDeep(workspace);
		try {
			setWorkspace(updatedWorkspace);
			const { message } = await updateWorkspace(getDatahubApi, updatedWorkspace);

			if (!stringIsNullOrEmpty(message)) {
				showSnackbar(`Unable to set workspace - ${message}`, snackbarVariants.error);
				return null;
			}
		} catch (e) {
			const msg = `failed to save workspace - ${e.message}`;
			console.error(msg);
			showSnackbar(msg, snackbarVariants.error);
			setWorkspace(previousWorkspace);
		} finally {
			setIsPartialLoading(false);
		}
	};

	const handleEnableNlpToggle = () => {
		if (workspace.enable_nlp) {
			// If we're disabling NLP, don't show the Alert prompt.
			saveWorkspace({ ...workspace, enable_nlp: false });
		} else {
			setShowNlpPrompt(true);
		}
	};

	const handleAveToggle = () => {
		const updatedWorkspace = {
			...workspace,
			show_ave: !workspace.show_ave
		};
		if (!updatedWorkspace.show_ave) {
			updatedWorkspace.currency_code = null;
		}
		saveWorkspace(updatedWorkspace);
	};

	const handleEnableMediaAssistantChange = () => {
		if (workspace.enable_ai_summarization && workspace.enable_media_assistant) {
			setIsAiMediaAssistantModalOpen(true);
		} else {
			saveWorkspace({
				...workspace,
				enable_media_assistant: !workspace.enable_media_assistant,
				enable_ai_summarization: !workspace.enable_media_assistant
			});
		}
	};

	const handleEnableAiSummarizationChange = () => {
		if (workspace.enable_ai_summarization) {
			setIsAiSummarizationModalOpen(true);
		} else {
			saveWorkspace({
				...workspace,
				enable_ai_summarization: !workspace.enable_ai_summarization
			});
		}
	};

	const handleAiModalAccept = () => {
		setIsAiSummarizationModalOpen(false);
		setIsAiMediaAssistantModalOpen(false);
		saveWorkspace({
			...workspace,
			enable_ai_summarization: false,
			enable_media_assistant: false
		});
	};

	const handleCurrencyCodeChange = (_e, currencyCode) => {
		saveWorkspace({ ...workspace, currency_code: currencyCode });
	};

	const handlePlanEndDateChange = (e) => {
		const updatedWorkspace = {
			...workspace,
			plan_end_date: htmlDateToIsoString(e.target.value)
		};

		const customDateValidation = validateObject(updatedWorkspace, workspaceValidationRules);
		setValidationState(customDateValidation);
		if (!customDateValidation.success) {
			return;
		}
		saveWorkspace(updatedWorkspace);
	};

	const handleAcceptEnableNlp = () => {
		setShowNlpPrompt(false);
		saveWorkspace({ ...workspace, enable_nlp: true });
	};

	const handleCancelEnableNlp = () => {
		setShowNlpPrompt(false);
	};

	const renderWorkspace = () => {
		return (
			<>
				<Grid container spacing={1}>
					<Grid item>
						<Market clientMarket={workspaceClientMarket} />
						<div className="partial-loading">
							{isPartialLoading && <LinearProgress className="partial-loading__linear-progress" variant="indeterminate" />}
						</div>
					</Grid>
					<Grid item>
						<LayoutSeparator />
					</Grid>
					<Grid item>
						<Details workspace={workspace} />
					</Grid>
					<Grid item>
						<LayoutSeparator />
					</Grid>
					<Grid item>
						<Plan
							plan={workspacePlan}
							workspace={workspace}
							onPlanEndDateChange={handlePlanEndDateChange}
							validation={validationState}
							dateTo={isoStringToHtmlFormat(workspace?.plan_end_date)}
						/>
					</Grid>
					<Grid item>
						<LayoutSeparator />
					</Grid>
					<Grid item>
						<Options
							market={market}
							workspace={workspace}
							onEnableNlpChange={handleEnableNlpToggle}
							onCurrencyCodeChange={handleCurrencyCodeChange}
							onShowAveChange={handleAveToggle}
							onEnableMediaAssistantChange={handleEnableMediaAssistantChange}
							onEnableAiSummarizationChange={handleEnableAiSummarizationChange}
							disabled={isPartialLoading || isLoading}
						/>
					</Grid>
					<Grid item>
						<LayoutSeparator />
					</Grid>
					<Grid item>
						<Grid container spacing={6}>
							<Grid item>
								<Inline horizontalAlignment={horizontalAlignment.rightAlignSiblings}>
									<Typography variant="h4">Content</Typography>
								</Inline>
							</Grid>
							<Grid item lg={6}>
								<Scopes workspace={workspace} scopes={workspaceScopes} setScopes={setWorkspaceScopes} />
							</Grid>
							<Grid item lg={6}>
								<ContentRights workspaceId={workspaceId} workspaceContentRights={workspaceContentRights} />
							</Grid>
						</Grid>
					</Grid>
					<Grid item>
						<LayoutSeparator />
					</Grid>
					<Grid item>
						<Users
							workspaceInvites={workspaceInvites}
							setWorkspaceInvites={setWorkspaceInvites}
							workspaceUsers={workspaceUsers}
							setWorkspaceUsers={setWorkspaceUsers}
							workspacePlan={workspacePlan}
							workspaceId={workspaceId}
						/>
					</Grid>
				</Grid>
				<Alert
					title="Enable NLP"
					message="Are you sure you want to enable NLP for this workspace? This aint cheap friendo"
					cancelLabel="No"
					acceptLabel="Yes"
					open={showNlpPrompt}
					handleCancel={handleCancelEnableNlp}
					handleAccept={handleAcceptEnableNlp}
				/>
			</>
		);
	};

	const renderContent = () => {
		if (isLoading) {
			return (
				<Grid item>
					<SkeletonWrapper />
				</Grid>
			);
		}

		if (!isNullOrUndefined(message)) {
			return (
				<Grid container>
					<Grid item>{message}</Grid>
				</Grid>
			);
		}

		if (isNullOrUndefined(workspace)) {
			<Grid container>
				<Grid item>No workspace loaded</Grid>
			</Grid>;
		}

		return renderWorkspace();
	};

	return (
		<Content>
			<Header header={isLoading ? '...' : workspace.name || ''} />
			{renderContent()}
			<AiSummarizationModal
				onAccept={handleAiModalAccept}
				onCancel={() => setIsAiSummarizationModalOpen(false)}
				isOpen={isAiSummarizationModalOpen}
			/>
			<AiMediaAssistantModal
				onAccept={handleAiModalAccept}
				onCancel={() => setIsAiMediaAssistantModalOpen(false)}
				isOpen={isAiMediaAssistantModalOpen}
			/>
		</Content>
	);
};

export default Workspace;
