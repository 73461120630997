import { extractError, handleError } from '../../../../components/Api';

export const getWorkspace = (getDatahubApi, workspace_id) => {
	return getDatahubApi().then((api) =>
		api
			.get(`/workspaces/v1/${workspace_id}`)
			.then(({ data }) => data)
			.catch((e) => handleError(`Failed to load workspace ${workspace_id}`, e, { workspace: {} }))
	);
};

export const updateWorkspace = (
	getDatahubApi,
	{ workspace_id, enable_nlp, plan_end_date, show_ave, currency_code, enable_media_assistant, enable_ai_summarization }
) => {
	return getDatahubApi().then((api) =>
		api
			.patch(`workspaces/v1/${workspace_id}`, {
				enable_nlp,
				plan_end_date,
				show_ave,
				currency_code,
				enable_media_assistant,
				enable_ai_summarization
			})
			.then(({ data }) => data)
			.catch((e) => ({ message: `Failed to update workspace - ${extractError(e)}` }))
	);
};
